import React, { useState } from 'react';
import { FiX, FiUser, FiLock } from "react-icons/fi";
import "../../style/style.css";
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

function Login({ show, handleClose, setUser }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ username: '', password: '' });
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleCloseWithoutFade = () => {
    handleClose();
  };

  const modalVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
  };

  const ClaimChecker = async () => {
    const tokenData = await fetchToken();
    if (!tokenData) {
      setLoading(false);
      return;
    }

    const { token, eventReferenceCode } = tokenData;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/participant-claimed-checker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          user_name: username, // Using the passed username prop
          event_currency: 'KRW',
          event_reference_code: eventReferenceCode,
          event_id: '1', // Replace with actual event_id if needed
        }),
      });

      const data = await response.json();
      if(data.status){
        localStorage.setItem('is_claimed', data.status);
        localStorage.setItem('claimed_prizes', JSON.stringify(data.claimed_prizes));
        localStorage.setItem('total_prize', JSON.stringify(data.total_prize));
        localStorage.setItem('have_available_claims', data.have_available_claims);

      }
    } catch (err) {
      setErrors("Error claiming prize");
    } finally {
      setLoading(false);
    }
  }


  const validateForm = () => {
    let valid = true;
    const newErrors = { username: '', password: '' };

    if (!username) {
      newErrors.username = t('userReq');
      valid = false;
    }

    if (!password) {
      newErrors.password = t('passReq');
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const fetchToken = async () => {
  
    try {
      const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: `${process.env.REACT_APP_EMAIL}`,
          password: `${process.env.REACT_APP_PASSWORD}`,
          event_id: `${process.env.REACT_APP_EVENT_ID}`,
          event_name: `${process.env.REACT_APP_EVENT_NAME}`
        })
      });

      const tokenData = await tokenResponse.json();
      if (tokenData.status) {
        return { token: tokenData.token, eventReferenceCode: tokenData.event_reference_code };
      } else {
        setErrors(tokenData.message);
        return null;
      }
    } catch (error) {
      setErrors('Error fetching token');
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);

      const tokenData = await fetchToken();
      if (!tokenData) {
        setLoading(false);
        return;
      }

      const { token, eventReferenceCode } = tokenData;

      try {
        
        if (tokenData) {
          const apiToken = tokenData.token;

          const loginResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/auth`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              user_name: username,
              login_code: password,
              event_id: 1,
              currency: 'KRW'
            })
          });

          const loginData = await loginResponse.json();
        if (loginData.status) {
          console.log('Login Successful:', loginData);
          const user = { name: username, token: loginData.token };
          localStorage.setItem('user', JSON.stringify(user));
          const claimChecker = await ClaimChecker();
          setUser(user);
          handleClose();
          window.location.reload();
        } else {
          setErrors({ username: '', password: t('logFailed') });
        }
        } else {
          setErrors({ username: '', password: tokenData.message });
        }
      } catch (error) {
        console.error('Error during login:', error);
        setErrors({ username: '', password: t('logFailed') });
      } finally {
        setLoading(false);
      }
    }

  };

  // test

  
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
          transition={{ duration: 0.3 }}
          className="modal-overlay"
          onClick={handleCloseWithoutFade}
        >
          <motion.div
            className="modal-content login"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <h4 className="modal-title">{t('login')}</h4>
              <button className="close-button" onClick={handleCloseWithoutFade}>
                <FiX />
              </button>
            </div>
            <div className="modal-body lang-container form">
              <form onSubmit={handleSubmit}>
                <label>{t('username')}</label>
                <div className='input-container'>
                  <input
                    type="text"
                    className='modal-input'
                    placeholder={t('username')}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <FiUser />
                </div>
                {errors.username && <small className="error-message">{errors.username}</small>}
                <br/>
                <label>{t('password')}</label>
                <div className='input-container'>
                  <input
                    type="password"
                    className='modal-input'
                    placeholder={t('password')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <FiLock />
                </div>
                {errors.password && <small className="error-message">{errors.password}</small>}
                <br />
                <button type="submit" disabled={loading}>
                  {loading ? t('loading') : t('submit')}
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button className="close-button" onClick={handleCloseWithoutFade}>
                {t('close')}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Login;
