

import React, { useState, useEffect } from 'react';
import AnimatedBoxEN from '../assets/en/Close_Box.png';
import AnimatedBoxKROpen from '../assets/en/Open_Box.png';
import AnimatedBoxKR from '../assets/en/Close_Box.png';
import TermsAndCondition from '../components/popup/TermsAndCondition';
import Suprise from '../components/popup/OpenBox';
import BackgroundVideo from '../assets/desktop.mp4';
import BackgroundVideo2 from '../assets/mobile.mp4';
import OpenBox from '../components/popup/OpenBox';
import Login from '../components/popup/Login';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import { color } from 'framer-motion';

function Home({ user, setUser }) {
  const { t, i18n } = useTranslation();

  const [showTerms, setShowTerms] = useState(false);
  const [showSup, setShowSup] = useState(false);
  const [showOpenBox, setShowOpenBox] = useState(false);
  const [currentGameAsset, setCurrentGameAsset] = useState(AnimatedBoxEN);
  const [originalGameAsset, setOriginalGameAsset] = useState(AnimatedBoxEN);
  const [showLogin, setShowLogin] = useState(false); // Control the display of the login popup

  useEffect(() => {
    if (i18n.language === 'kr') {
      setCurrentGameAsset(AnimatedBoxKR);
      setOriginalGameAsset(AnimatedBoxKR);
    } else {
      setCurrentGameAsset(AnimatedBoxEN);
      setOriginalGameAsset(AnimatedBoxEN);
    }
  }, [i18n.language]);

  const handleShowOpenBox = () => {
    
    if (!user) {
      setShowLogin(true);
    } else {
      setCurrentGameAsset(AnimatedBoxKROpen);
      setTimeout(() => {
        setShowOpenBox(true);
      }, 1000);
      
    }
  };

  const handleCloseOpenBox = () => {
    setShowOpenBox(false);
    setCurrentGameAsset(originalGameAsset);
    };

  const handleShowTerms = () => setShowTerms(true);
  const handleCloseTerms = () => setShowTerms(false);

  const handleShowSup = () => {
    if (!user) {
      setShowLogin(true);
    } else {
      setCurrentGameAsset(AnimatedBoxKROpen);
      setTimeout(() => {
        setShowSup(true);
      }, 1000);
      
      // setCurrentGameAsset(i18n.language === 'kr' ? AnimatedBoxKR : AnimatedBoxEN); 
    }
  };

  const handleCloseSup = () => {
    setShowSup(false);
    setCurrentGameAsset(originalGameAsset); 
  };

  const handleGameAssetClick = () => {
    handleShowOpenBox();
  };

  const handleTermsLinkClick = () => {
    handleShowTerms(); 
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  return (
    <div className='appBox'>
      <video autoPlay muted loop playsInline className='video-background'>
        <source src={BackgroundVideo} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <video autoPlay muted loop playsInline className='mobile-video-background'>
        <source src={BackgroundVideo2} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <div className='container clearfix'>
        <p dangerouslySetInnerHTML={{ __html: t('100chance') }} className="chance-100"></p>
        <div className='game-assets' onClick={handleGameAssetClick}>
        <Image 
          src={currentGameAsset} 
          alt="Lucky Box" />
        </div>

        <div className='game-btn'>
          <div onClick={handleShowSup} className='btn-dsgn'><button>{t('clicksurprise')}</button></div>
          <a href='#' onClick={handleTermsLinkClick}>{t('termsandconditions')}</a>
        </div>
        <OpenBox show={showOpenBox} handleClose={handleCloseOpenBox} username={user?.name} />
        <TermsAndCondition show={showTerms} handleClose={handleCloseTerms} />
        <Suprise show={showSup} handleClose={handleCloseSup} username={user?.name} />
        <Login show={showLogin} handleClose={handleCloseLogin} setUser={setUser} />
      </div>
    </div>
  );
}

export default Home;
