

import React, { useEffect, useState } from 'react';
import './App.css';
import './style/style.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Preloader from './components/preloader/Preloader';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const AppContent = ({ user, setUser }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const language = location.pathname.split('/')[1];
    if (language && (language === 'en' || language === 'kr')) {
      i18n.changeLanguage(language);
      localStorage.setItem('language', language); 
    } else {
      const savedLanguage = localStorage.getItem('language') || 'en';
      i18n.changeLanguage(savedLanguage);
      if (!location.pathname.includes(`/${savedLanguage}`)) {
        navigate(`/${savedLanguage}`);
      }
    }
  }, [location, navigate]);

  return (
    <>
      <Navbar user={user} setUser={setUser} />
      <Routes>
        <Route path='/:lng' element={<Home user={user} setUser={setUser} />} />
        <Route path='/' element={<Home user={user} setUser={setUser} />} />
      </Routes>
    </>
  );
};

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Set a shorter timeout
  }, []);

  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <AnimatePresence exitBeforeEnter={false}>
          {loading ? (
            <Preloader key="preloader" />
          ) : (
            <motion.div
              key="contentWrapper"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1, ease: 'easeInOut' }} // Adjusted for a quicker transition
              className="contentWrapper fadeOut"
            >
              <Router>
                <AppContent user={user} setUser={setUser} />
              </Router>
            </motion.div>
          )}
        </AnimatePresence>
      </I18nextProvider>
    </div>
  );
}

export default App;

