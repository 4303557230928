import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

function OpenBox({ show, handleClose, username }) {
  const { t, i18n } = useTranslation();
  const [prize, setPrize] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isClaimed, setIsClaimed] = useState(localStorage.getItem("is_claimed"));
  const [isHaveAvailableClaims, setHaveAvailableClaims] = useState(localStorage.getItem("have_available_claims"));

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  };

  useEffect(() => {
    ClaimChecker();
    if ('false' === isHaveAvailableClaims && isClaimed) {
      const totalPrize = localStorage.getItem("total_prize");
      setPrize(formatNumber(Number(totalPrize)));
      setMessage(t('alreadyclaimed'));
    }
  }, [isHaveAvailableClaims, i18n.language]); // Re-run on language change

  useEffect(() => {
    const have_available_claims = localStorage.getItem("have_available_claims");
    setHaveAvailableClaims(have_available_claims);
  }, []);
  
  useEffect(() => {
    const is_claimed = localStorage.getItem("is_claimed");
    setIsClaimed(is_claimed);
  }, []);

  const ClaimChecker = async () => {
    const tokenData = await fetchToken();
    if (!tokenData) {
      setLoading(false);
      return;
    }

    const { token, eventReferenceCode } = tokenData;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/participant-claimed-checker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          user_name: username, // Using the passed username prop
          event_currency: 'KRW',
          event_reference_code: eventReferenceCode,
          event_id: '1', // Replace with actual event_id if needed
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        localStorage.setItem('is_claimed', data.status);
        localStorage.setItem('claimed_prizes', JSON.stringify(data.claimed_prizes));
        localStorage.setItem('total_prize', data.total_prize);
        localStorage.setItem('have_available_claims', data.have_available_claims);
      }
    } catch (err) {
      setError(t('errorclaimingprize')); // Translate the error message
    } finally {
      setLoading(false);
    }
  };

  const fetchToken = async () => {
    try {
      const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: `${process.env.REACT_APP_EMAIL}`,
          password: `${process.env.REACT_APP_PASSWORD}`,
          event_id: `${process.env.REACT_APP_EVENT_ID}`,
          event_name: `${process.env.REACT_APP_EVENT_NAME}`
        })
      });

      const tokenData = await tokenResponse.json();
      if (tokenData.status) {
        return { token: tokenData.token, eventReferenceCode: tokenData.event_reference_code };
      } else {
        setError(t(`error_messages.${tokenData.message}`)); // Translate the error message if key exists
        return null;
      }
    } catch (error) {
      setError(t('errorfetchingtoken')); // Translate the error message
      return null;
    }
  };

  const handleClaimPrize = async () => {
    setLoading(true);
    setError('');

    const tokenData = await fetchToken();
    if (!tokenData) {
      setLoading(false);
      return;
    }

    const { token, eventReferenceCode } = tokenData;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/participant-claim-prize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          user_name: username,
          event_currency: 'KRW',
          event_reference_code: eventReferenceCode,
          event_id: '1', // Replace with actual event_id if needed
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        const formattedPrize = formatNumber(Number(data.total_prize));
        const prizeKey = data.claimed_prizes && data.claimed_prizes.length > 0 ? t('alreadyclaimed') : t('successful');
        
        setPrize(formattedPrize);
        setMessage(t(prizeKey));

        ClaimChecker();
      } else {
        setError(t(`error_messages.${data.message}`)); // Translate error message if available
      }
    } catch (err) {
      setError(t('errorclaimingprize')); // Translate the error message
    } finally {
      setLoading(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay noblur" onClick={handleClose}>
      <div className="modal-content surprise-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          {/* <h4 className="modal-title">{t('congratulations')}</h4> */}
          <h4 className="modal-title"></h4>
          <button className="close-button" onClick={handleClose}>
            <FiX />
          </button>
        </div>
        <div className="modal-body surprise-box">
          <h5>{t('youwon')}</h5>
          {loading ? (
            <p>{t('loading')}</p>
          ) : prize ? (
            <>
              <span className='prize-money'>{prize}</span>
              <p className='prize-message'>{message}</p>
            </>
          ) : (
            error && <p className="error-text">{error}</p>
          )}
          <p>{t('forfurtherdetails')}</p>
        </div>
        <div className="modal-footer">
          <div className='btn-dsgn'>
            {prize ? (
              <button onClick={handleClose}>
                {t('alreadyclaimedbonus')}
              </button>
            ) : (
              <button onClick={handleClaimPrize}>
                {t('claimbonus')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenBox;
