import React from 'react';
import { FiX } from "react-icons/fi";
import "../../../style/paskopanalo/style-paskopanalo.css";
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, color } from 'framer-motion';

function TermsAndCondition({ show, handleClose }) {
  const { t } = useTranslation();
  const handleCloseWithoutFade = () => {
    handleClose();
  };

  const modalVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div 
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={modalVariants}
        transition={{ duration: 0.3 }}
        className="modal-overlay" 
        onClick={handleCloseWithoutFade}
        >
          <motion.div className="modal-content-ph" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header-ph">
              <h4 className="modal-title-ph">{t('termsandconditions')}</h4>
              <button className="close-button" onClick={handleCloseWithoutFade}>
                <FiX />
              </button>
            </div>
            <div className="modal-body-ph">
              {/* <div dangerouslySetInnerHTML={{ __html: t('htmlTerms') }}></div> */}
              <p>This Christmas, it’s not just about giving – it’s about giving AND earning and we want to make it even more special for YOU! MCW Christmas Gift Box is designed to help you earn generous rewards while spreading festive joy to everyone.</p>
              <br/>
              <h4 style={{textAlign:'left', color:'white',marginLeft:'10px',marginBottom:'20px'}}>Let’s enjoy the holidays with MCW and get a chance to win Cash prizes with MCW Christmas Gift Box.
              </h4>

              <h3 style={{color:"#ffe116",marginBottom:'20px'}}>Get a chance to win 100,000 Pesos in one ticket!
              </h3>

              <h4 style={{textAlign:'left', color:'white',marginLeft:'10px'}}>How to Earn tickets for Christmas Gift Box?</h4>
              <p><strong>1. Bring in New Active players</strong></p>
              <p>Earn a Ticket by bringing in New Active Players. For Every 5 new Active Players, you will get 1 ticket. Maximum of 5 tickets can be earned.
              </p>
              <p style={{color:'#f3d62e'}}>Example:</p>
              <p style={{color:'#f3d62e'}}>Affiliate A: 20 New Active Players = 4 Tickets<br/>
Affiliate B: 40 New Active Players = 5 Tickets<br/>
Affiliate C: 10 New Active Players = 2 Tickets</p>
              
              <p style={{marginTop:'15px'}}><strong>2. Affiliate Total Deposits</strong></p>
              <p>Earn a ticket for every 100,000 deposits accumulated during the promotion period. Maximum of 5 Tickets can be earned.
              </p>
              <p style={{color:'#f3d62e'}}>Example</p>
              <p style={{color:'#f3d62e'}}>Affiliate A Total Deposits : 350,000 Deposits : 3 Tickets<br/>
              Affiliate B Total Deposits : 500,000 Deposits: 5 Tickets<br/>
              Affiliate C Total Deposits : 50,000 Deposits : 0 Tickets
              </p>
              <br/>
              <h4 style={{textAlign:'left', color:'white',marginLeft:'10px'}}>Terms & Conditions</h4>
              <p>1. This promotion is available from November 25,2024 to December 23, 2024 and is applicable for all New and Existing Affiliates.</p>
              <p>2. Affiliates must join our official MCW Affiliate Telegram Channel to be eligible.</p>
              <p>3. Total Deposits and New Active Players will be counted from November 25, 2024 until December 23, 2024. </p>
              <p>4. Affiliates may claim their tickets and open the Christmas Gift Box on the 25th of December until the 28th of December.</p>
              <p>5. To be considered as an active player, a player must make a deposit and play. A minimum of 100 turnover is required to be counted as an active player.</p>
              <p>6. This promotion is based on a first-come-first-served basis. The outcome is completely random and all qualified affiliates have equal chances to win a prize from the Christmas Gift Box. You have a better chance if you accumulated more Tickets.</p>
              <p>7.Once the Grand Prize of 100,000.00 PHP has already been claimed by an affiliate, it will automatically be removed from the list of prizes.
              Example: If Affiliate A has already won the Grand Prize before Affiliate B draws in the Christmas Gift Box, Affiliate B will no longer have the chance to win the Grand Prize.”</p>
              <p>8. No wagering requirement is required to withdraw the bonus.</p>
              <p>9. The prizes you won from the Christmas Gift Box will be credited directly to the Affiliates Linked Player Account.</p>
              <p>10. MCW reserves the right to withdraw the affiliate’s commission if the account is suspected of abuse and/or non-compliance with the terms and conditions.</p>
              <p>11. MCW reserves the right to make the final decision.</p>
            </div>
            <div className="modal-footer">
              <button className="close-button" onClick={handleCloseWithoutFade}>
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default TermsAndCondition;
