import React, { useEffect, useState } from 'react';
import { FiX } from "react-icons/fi";
import "../../../style/paskopanalo/style-paskopanalo.css";
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import md5 from 'crypto-js/md5';

function PotMoney({ show, handleClose }) {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTicketPrizeSummary = async () => {
    setLoading(true);
    setError(null);
  
    try {
      // Step 1: Fetch the token and event_reference_code
      const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: process.env.REACT_APP_EMAIL,
          password: process.env.REACT_APP_PASSWORD,
          event_id: parseInt(process.env.REACT_APP_EVENT_ID, 10), // Ensure it's an integer
          event_name: process.env.REACT_APP_EVENT_NAME,
          payload: generatePayload()
        }),
      });

      const payloadData = {
        email: `${process.env.REACT_APP_EMAIL}`,
        password: `${process.env.REACT_APP_PASSWORD}`,
        event_id: `${process.env.REACT_APP_EVENT_ID}`,
        event_name: `${process.env.REACT_APP_EVENT_NAME}`,
        payload: generatePayload()
      };
  
      
      const tokenData = await tokenResponse.json();
      if (!tokenData.status) {
        throw new Error(tokenData.message || 'Failed to fetch token');
      }
  
      const { token, event_reference_code } = tokenData;
  
      // Step 2: Use the token and event_reference_code to fetch the ticket prize summary
      const summaryResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-ticket-prize-summary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Add Bearer token here
        },
        body: JSON.stringify({
          event_id: parseInt(process.env.REACT_APP_EVENT_ID, 10), // Ensure it's an integer
          event_reference_code, // Pass the reference code
          event_currency: 'PHP', // Static value
        }),
      });
  
      const summaryData = await summaryResponse.json();
      if (summaryData.status !== 'success') {
        throw new Error(summaryData.message || 'Failed to fetch prize summary');
      }
  
      setTableData(summaryData.data); // Store the response data in state
    } catch (err) {
      setError(err.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };
  
  const generatePayload = () => {
    const email = process.env.REACT_APP_EMAIL;
    const password = process.env.REACT_APP_PASSWORD;
    const eventId = process.env.REACT_APP_EVENT_ID;
    const eventName = process.env.REACT_APP_EVENT_NAME;
    const secretKey = process.env.REACT_APP_SECRET_KEY;
  
    const payloadString = `${email}${password}${eventId}${eventName}${secretKey}`;
    return md5(payloadString).toString();
  };

  useEffect(() => {
    if (show) {
      fetchTicketPrizeSummary();
    }
  }, [show]);

  const modalVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
          transition={{ duration: 0.3 }}
          className="modal-overlay"
          onClick={handleClose}
        >
          <motion.div className="modal-content-pot-summary" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header-pot-summary">
              <h4 className="modal-title">Available Prizes</h4>
              <button className="close-button" onClick={handleClose}>
                <FiX />
              </button>
            </div>
            <div className="modal-body-pot-summary">
              {loading ? (
                <p>{t('loading')}</p>
              ) : error ? (
                <p className="text-red-500">{t('error')}: {error}</p>
              ) : (
                <table className="w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border px-4 py-2">{t('Prize Amount')}</th>
                      <th className="border px-4 py-2">{t('No. of Prizes')}</th>
                      <th className="border px-4 py-2">{t('Total')}</th>
                      <th className="border px-4 py-2">{t('Remaining Quantity')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index) => (
                      <tr key={index} className="border-b">
                        <td className="border px-4 py-2 text-center">{item.prize_amount}</td>
                        <td className="border px-4 py-2 text-center">{item.no_of_prizes}</td>
                        <td className="border px-4 py-2 text-center">{item.total}</td>
                        <td className="border px-4 py-2 text-center">{item.remaining_quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="modal-footer">
              <button className="close-button" onClick={handleClose}>
                {t('close')}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default PotMoney;
