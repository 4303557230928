import React, { useState, useEffect } from 'react';
import Logo from '../../../assets/luckbox/logo.png';
import KRflag from '../../../assets/luckbox/KR.png';
import Enflag from '../../../assets/luckbox/KR.png';
import Language from '../../popup/KR/Language';
import Login from '../../popup/KR/Login';
import { useTranslation } from 'react-i18next';
import { FiUser, FiLogOut, FiChevronDown } from "react-icons/fi";

const AUTO_LOGOUT_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds

function Navbar({ user, setUser }) {
  const { t, i18n } = useTranslation();
  const [showLang, setShowLang] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChanged = (lng) => {
      setCurrentLang(lng);
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      resetLogoutTimer();
    }
  }, [setUser]);

  useEffect(() => {
    const handleUserActivity = () => resetLogoutTimer();
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    return () => {
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
    };
  }, []);

  const resetLogoutTimer = () => {
    clearTimeout(window.logoutTimer);
    window.logoutTimer = setTimeout(() => {
      handleLogout();
    }, AUTO_LOGOUT_TIME);
  };

  const handleShowLang = () => setShowLang(true);
  const handleCloseLang = () => setShowLang(false);

  const handleShowLogin = () => setShowLogin(true);
  const handleCloseLogin = () => setShowLogin(false);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    localStorage.removeItem("is_claimed");
    localStorage.removeItem("claimed_prizes");
    localStorage.removeItem("total_prize");
    localStorage.removeItem("have_available_claims");
    setDropdownOpen(false);
    clearTimeout(window.logoutTimer);
    window.location.reload();
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const getFlag = () => {
    switch (currentLang) {
      case 'kr':
        return KRflag;
      case 'en':
      default:
        return Enflag;
    }
  };

  return (
    <div className="navbar">
      <div className='container'>
        <div className="left">
          <h1><img src={Logo} alt="Logo" /></h1>
        </div>
        <div className="right">
          <ul className='clearfix'>
            <li>
              <a href="#" onClick={handleShowLang}>
                <img src={getFlag()} alt="Language Flag" />
              </a>
            </li>
            {user ? (
              <li className='user-dropdown clearfix'>
                <a href="#" className='user-trigger' onClick={(e) => { e.preventDefault(); toggleDropdown(); }}>
                  <FiUser className='user-icon' /> <span>{t('welcome')},</span> <span className='user-name'>{user.name}</span> <FiChevronDown className='arrowd-icon' />
                </a>
                <ul className='dropdown-menu' style={{ display: dropdownOpen ? 'block' : 'none' }}>
                  <li>
                    <a href="#" className='logout-btn' onClick={handleLogout}>
                      <FiLogOut /> {t('logout')}
                    </a>
                  </li>
                </ul>
              </li>
            ) : (
              <>
                <li><a href="#" className='signup'>{t('signup')}</a></li>
                <li><a href="#" className='login' onClick={(e) => { e.preventDefault(); handleShowLogin(); }}>{t('login')}</a></li>
              </>
            )}
          </ul>
        </div>
      </div>
      <Language show={showLang} handleClose={handleCloseLang} />
      <Login show={showLogin} handleClose={handleCloseLogin} setUser={setUser} />
    </div>
  );
}

export default Navbar;
