import React from 'react';
import { FiX } from "react-icons/fi";
import "../../../style/luckybox/style-luckybox.css";
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

function TermsAndCondition({ show, handleClose }) {
  const { t } = useTranslation();
  const handleCloseWithoutFade = () => {
    handleClose();
  };

  const modalVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div 
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={modalVariants}
        transition={{ duration: 0.3 }}
        className="modal-overlay" 
        onClick={handleCloseWithoutFade}
        >
          <motion.div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h4 className="modal-title">{t('termsandconditions')}</h4>
              <button className="close-button" onClick={handleCloseWithoutFade}>
                <FiX />
              </button>
            </div>
            <div className="modal-body">
              <div dangerouslySetInnerHTML={{ __html: t('htmlTerms') }}></div>
            </div>
            <div className="modal-footer">
              <button className="close-button" onClick={handleCloseWithoutFade}>
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default TermsAndCondition;
