import React from 'react';
import { FiX } from "react-icons/fi";
import KRflag from '../../../assets/paskopanalo/KR.png';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { useNavigate } from 'react-router-dom';

function Language({ show, handleClose }) {
  
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCloseWithoutFade = () => {
    handleClose();
  };

  const changeLanguage = (lng) => {
    try {
      i18n.changeLanguage(lng);
      localStorage.setItem('language', lng); 
      navigate(`/${lng}`);
      handleClose();
    } catch (error) {
      console.error('Error updating language:', error);
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div 
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
          transition={{ duration: 0.3 }}
          className="modal-overlay" 
          onClick={handleCloseWithoutFade}
        >
          <motion.div 
            className="modal-content" 
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <h4 className="modal-title">{t('language')}</h4>
              <button className="close-button" onClick={handleCloseWithoutFade}>
                <FiX />
              </button>
            </div>
            <div className="modal-body lang-container">
              <img src={KRflag} alt="Korean Flag" />
              <div className='lang-div'>
                <a href="#" className={i18n.language === 'kr' ? 'active' : ''} onClick={(e) => {e.preventDefault(); changeLanguage('kr')}}>한국어</a>
                <a href="#" className={i18n.language === 'en' ? 'active' : ''} onClick={(e) => {e.preventDefault();changeLanguage('en')}}>English</a>
              </div>
            </div>
            <div className="modal-footer">
              <button className="close-button" onClick={handleCloseWithoutFade}>
                {t('close')}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Language;
