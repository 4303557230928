import React from 'react';
import GameAsset02 from '../../../assets/paskopanalo/Preloader.gif';
import '../../../style/luckybox/style-luckybox.css'
import { motion } from 'framer-motion';

function Preloader() {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      exit={{ opacity: 0 }}
    >
      <div className="preloader-content">
        <img src={GameAsset02} alt="Game Asset" height={100} />
      </div>
    </motion.div>
  );
}

export default Preloader;
