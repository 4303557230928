import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import md5 from 'crypto-js/md5';

function OpenBox({ show, handleClose, username, haveAvailableClaims, onSetAvailableClaims }) {

  const { t, i18n } = useTranslation();
  const [prize, setPrize] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isClaimed, setIsClaimed] = useState(localStorage.getItem('is_claimed'));
  const [isHaveAvailableClaims, setHaveAvailableClaims] = useState(localStorage.getItem('have_available_claims'));
  const [ticketList, setTicketList] = useState([]);

  useEffect(() => {
    ClaimChecker();

    if (isHaveAvailableClaims === 'false' && isClaimed) {
      const totalPrize = localStorage.getItem('total_prize');
      const claimedPrizeList = localStorage.getItem('claimed_prizes');

      if (claimedPrizeList) {
        const parsedPrizeList = JSON.parse(claimedPrizeList);

        // Formatting the prize display
        if (totalPrize) {
          const match = totalPrize.match(/^(\d+)/); // Match the first number before any parentheses
          const numericPrize = match ? match[1] : null;

          const prizeInParentheses = totalPrize.match(/\((\d+)\)/); // Match any number inside parentheses
          const formattedPrizeInParentheses = prizeInParentheses ? formatNumber(Number(prizeInParentheses[1])) : null;

          const formattedPrize = numericPrize && numericPrize !== '0' ? formatNumber(Number(numericPrize)) : null;

          if (formattedPrize) {
            setPrize(`${formattedPrize} ${prizeInParentheses ? prizeInParentheses[1] : ''}`);
          } else if (formattedPrizeInParentheses) {
            setPrize(formattedPrizeInParentheses);
          } else {
            setPrize(prizeInParentheses ? prizeInParentheses[1] : totalPrize);
          }

          setMessage(t('alreadyclaimed'));
        }
      }
    }
  }, [isHaveAvailableClaims, i18n.language]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    const have_available_claims = localStorage.getItem('have_available_claims');
    setHaveAvailableClaims(have_available_claims);
  }, []);

  useEffect(() => {
    const is_claimed = localStorage.getItem('is_claimed');
    setIsClaimed(is_claimed);
  }, []);

  const ClaimChecker = async () => {
    const tokenData = await fetchToken();
    if (!tokenData) {
      setLoading(false);
      return;
    }

    const { token, eventReferenceCode } = tokenData;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/participant-claimed-checker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_name: username,
          event_currency: 'PHP',
          event_reference_code: eventReferenceCode,
          event_id: '1',
        }),
      });

      const data = await response.json();
      if (data.status) {
        localStorage.setItem('is_claimed', data.status);
        localStorage.setItem('claimed_prizes', JSON.stringify(data.claimed_prizes));
        localStorage.setItem('total_prize', data.total_prize);
        localStorage.setItem('have_available_claims', data.have_available_claims);
      }
    } catch (err) {
      setError(t('errorclaimingprize'));
    } finally {
      setLoading(false);
    }
  };


  const fetchToken = async () => {
    try {
      const envSecretKey = process.env.REACT_APP_SECRET_KEY; // Ensure you have this in your .env file
      const payloadString = 
        `${process.env.REACT_APP_EMAIL}` +
        `${process.env.REACT_APP_PASSWORD}` +
        `${process.env.REACT_APP_EVENT_ID}` +
        `${process.env.REACT_APP_EVENT_NAME}` +
        envSecretKey;
  
      const payload = md5(payloadString).toString(); // Use md5 to hash the payload and convert to string
  
      const payloadData = {
        email: `${process.env.REACT_APP_EMAIL}`,
        password: `${process.env.REACT_APP_PASSWORD}`,
        event_id: `${process.env.REACT_APP_EVENT_ID}`,
        event_name: `${process.env.REACT_APP_EVENT_NAME}`,
        payload: payload // Include the hashed payload
      };
  
      // Log the payload to the console
      console.log("Payload being sent:", payloadData);
  
      const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payloadData)
      });
  
      const tokenData = await tokenResponse.json();
      if (tokenData.status) {
        return { token: tokenData.token, eventReferenceCode: tokenData.event_reference_code };
      } else {
        setError(t(`error_messages.${tokenData.message}`)); // Translate the error message if key exists
        return null;
      }
    } catch (error) {
      setError(t('errorfetchingtoken')); // Translate the error message
      return null;
    }
  };

  const handleClaimPrize = async () => {
    setLoading(true);
    setError('');

    const tokenData = await fetchToken();
    if (!tokenData) {
      setLoading(false);
      return;
    }

    const { token, eventReferenceCode } = tokenData;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/participant-claim-prize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_name: username,
          event_currency: 'PHP',
          event_reference_code: eventReferenceCode,
          event_id: '1',
        }),
      });

      const data = await response.json();
      if (data.status) {
        let prizeValue = data.total_prize;
        const fetchedTicketList = data.data;
        setTicketList(fetchedTicketList);

        const prizeAmount = formatNumber(Number(prizeValue));
        setPrize(prizeAmount);
        setMessage(t('successful'));

        ClaimChecker();
      } else {
        setError(t(`error_messages.${data.message}`));
      }
    } catch (err) {
      setError(t('errorclaimingprize'));
    } finally {
      setLoading(false);
    }
  };

  const shuffle = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay noblur" onClick={handleClose}>
      <div className="modal-content-ph surprise-container-ph" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header-ph">
          <button className="close-button" onClick={handleClose}>
            <FiX />
          </button>
        </div>
        <div className="modal-body-ph surprise-box">
          
            
            {loading ? (
              <p>{t('loading')}</p>
            ) : (
              <>
              <>
              <div className='modal-body-ph surprise-box-ph header'>
                <h5>{t('youwon')}</h5>
                {prize && <span className="prize-money">{prize}</span>}
                {message && <p className="prize-message">{message}</p>}
              </div>
              </>
            
            <>
              {isClaimed === 'true' ? (
  <div className="prize-table">
    <table className="w-full border-collapse border border-gray-300">
      <thead>
        <tr className="bg-gray-100">
          <th className="border px-4 py-2">{t('Prize Amount')}</th>
          <th className="border px-4 py-2">{t('Quantity')}</th>
          <th className="border px-4 py-2">{t('Total Prize')}</th>
        </tr>
      </thead>
      <tbody>
        {/* Safely parse claimed_prizes and access the first object's prize_list */}
        {JSON.parse(localStorage.getItem('claimed_prizes'))?.[0]?.prize_list?.map((prize, index) => (
          <tr key={index} className="border-b">
            <td className="border px-4 py-2 text-center">
              {formatNumber(Number(prize.prize_amount))}
            </td>
            <td className="border px-4 py-2 text-center">{prize.quantity}</td>
            <td className="border px-4 py-2 text-center">
              {formatNumber(Number(prize.prize_amount) * prize.quantity)}
            </td>
          </tr>
        )) || (
          <tr>
            <td colSpan={3} className="text-center text-gray-500">
              {t('No claimed prizes available.')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
) : ticketList.length > 0 ? (
  <div className='prize-list-container'>
  <div className="prize-list">
    {ticketList.map((list, index) =>
      // Flatten the prizes array to handle quantities > 1
      shuffle(
        list.prizes
          .flatMap((prize) =>
            // Repeat the prize `quantity` times but with `quantity: 1`
            Array.from({ length: prize.quantity }, () => ({
              prize_amount: prize.prize_amount,
              quantity: 1,
              total_prize: prize.prize_amount, // Total for each single prize is the same as prize_amount
            }))
          )
      ).map((prize, prizeIndex) => (
        <div key={`${index}-${prizeIndex}`} className="prize-item">
          <p class="prize-firstclaim">
            <strong className='yougot'>{t('You got')}</strong> {prize.quantity} x {formatNumber(Number(prize.prize_amount))} ={' '}
            {formatNumber(Number(prize.total_prize))}
          </p>
        </div>
      ))
    )}
  </div>
</div>

) : null}


            </>
            </>
          )}
        </div>
              <div className="modal-footer">
                <button className="btn-dsgn-ph" onClick={prize ? handleClose : handleClaimPrize}>
                  {t(prize ? 'alreadyclaimedbonus' : 'claimbonus')}
                </button>
              </div>
      </div>
    </div>
  );
}

export default OpenBox;
