



import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';
import md5 from 'crypto-js/md5';

function OpenBox({ show, handleClose, username }) {
  const { t, i18n } = useTranslation();
  const [prize, setPrize] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isClaimed, setIsClaimed] = useState(localStorage.getItem("is_claimed"));
  const [isHaveAvailableClaims, setHaveAvailableClaims] = useState(localStorage.getItem("have_available_claims"));
  

  useEffect(() => {
    ClaimChecker();
  
    if (isHaveAvailableClaims === 'false' && isClaimed) {
      const totalPrize = localStorage.getItem("total_prize");
      const claimedPrizes = JSON.parse(localStorage.getItem("claimed_prizes") || '[]');
      
      if (claimedPrizes.length > 0) {
        // Get the first claimed prize
        const claimedPrize = claimedPrizes[0];
  
        // If there's an amount and no text_prize, format the amount
        if (claimedPrize.amount && !claimedPrize.text_prize) {
          setPrize(formatNumber(claimedPrize.amount));
        } else {
          // Handle totalPrize: If total prize is "0 (cake)" format, show only the text
          const matchTextInParentheses = totalPrize.match(/\((.*?)\)/);
          if (totalPrize.startsWith("0") && matchTextInParentheses) {
            const textInParentheses = matchTextInParentheses[1];
  
            // Check if the text is a number (e.g., 0 (1123)), and format it
            if (!isNaN(textInParentheses)) {
              setPrize(formatNumber(Number(textInParentheses)));
            } else {
              // If it's not a number, show the text (e.g., "cake")
              setPrize(textInParentheses);
            }
          } else {
            // Fallback: Handle the case where total_prize is not in "0 (text)" or "0 (number)" format
            const match = totalPrize.match(/^(\d+)/); // Match the first number before any parentheses
            const numericPrize = match ? match[1] : null;
  
            // Extract the number inside parentheses (if any)
            const prizeInParentheses = totalPrize.match(/\((\d+)\)/); // Match any number inside parentheses
  
            if (numericPrize === '0' && prizeInParentheses) {
              // Format the number inside parentheses (e.g., 1000 -> 1,000.00)
              setPrize(formatNumber(Number(prizeInParentheses[1])));
            } else if (numericPrize !== '0' && prizeInParentheses) {
              // If the prize amount is not 0, display both the amount and the prize in parentheses
              setPrize(`${formatNumber(Number(numericPrize))} ${prizeInParentheses[1]}`);
            } else {
              // Fallback: If there's no valid numeric prize, show the entire prize string
              setPrize(totalPrize);
            }
          }
        }
  
        setMessage(t('alreadyclaimed'));
      }
    }
  }, [isHaveAvailableClaims, i18n.language]);
  
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  

  useEffect(() => {
    const have_available_claims = localStorage.getItem("have_available_claims");
    setHaveAvailableClaims(have_available_claims);
  }, []);

  useEffect(() => {
    const is_claimed = localStorage.getItem("is_claimed");
    setIsClaimed(is_claimed);
  }, []);

  const ClaimChecker = async () => {
    const tokenData = await fetchToken();
    if (!tokenData) {
      setLoading(false);
      return;
    }

    const { token, eventReferenceCode } = tokenData;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/participant-claimed-checker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          user_name: username, // Using the passed username prop
          event_currency: 'KRW',
          event_reference_code: eventReferenceCode,
          event_id: '1', // Replace with actual event_id if needed
        }),
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        localStorage.setItem('is_claimed', data.status);
        localStorage.setItem('claimed_prizes', JSON.stringify(data.claimed_prizes));
        localStorage.setItem('total_prize', data.total_prize);
        localStorage.setItem('have_available_claims', data.have_available_claims);
      }
    } catch (err) {
      setError(t('errorclaimingprize')); // Translate the error message
    } finally {
      setLoading(false);
    }
  };

  const fetchToken = async () => {
    try {
      const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: `${process.env.REACT_APP_EMAIL}`,
          password: `${process.env.REACT_APP_PASSWORD}`,
          event_id: `${process.env.REACT_APP_EVENT_ID}`,
          event_name: `${process.env.REACT_APP_EVENT_NAME}`,
          payload: generatePayload()
        })
      });

      const payloadData = {
        email: `${process.env.REACT_APP_EMAIL}`,
        password: `${process.env.REACT_APP_PASSWORD}`,
        event_id: `${process.env.REACT_APP_EVENT_ID}`,
        event_name: `${process.env.REACT_APP_EVENT_NAME}`,
        payload: generatePayload()
      };

      const tokenData = await tokenResponse.json();
      if (tokenData.status) {
        return { token: tokenData.token, eventReferenceCode: tokenData.event_reference_code };
      } else {
        setError(t(`error_messages.${tokenData.message}`)); // Translate the error message if key exists
        return null;
      }
    } catch (error) {
      setError(t('errorfetchingtoken')); // Translate the error message
      return null;
    }
  };

  const generatePayload = () => {
    const email = process.env.REACT_APP_EMAIL;
    const password = process.env.REACT_APP_PASSWORD;
    const eventId = process.env.REACT_APP_EVENT_ID;
    const eventName = process.env.REACT_APP_EVENT_NAME;
    const secretKey = process.env.REACT_APP_SECRET_KEY;
  
    const payloadString = `${email}${password}${eventId}${eventName}${secretKey}`;
    return md5(payloadString).toString();
  };

  const handleClaimPrize = async () => {
    setLoading(true);
    setError('');

    const tokenData = await fetchToken();
    if (!tokenData) {
      setLoading(false);
      return;
    }

    const { token, eventReferenceCode } = tokenData;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/participant-claim-prize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          user_name: username,
          event_currency: 'KRW',
          event_reference_code: eventReferenceCode,
          event_id: '1', // Replace with actual event_id if needed
        }),
      });

      const data = await response.json();
      console.log(data);

      if (data.status) {
        let prizeValue = data.total_prize;
  
        // Check if prizeValue is a number or a string
        let prizeAmount, prizeText;
        if (typeof prizeValue === 'string' && isNaN(Number(prizeValue))) {
          // If it's a string and not a number
          prizeText = prizeValue;
          prizeAmount = null;
        } else {
          // If it's a number or numeric string
          prizeAmount = formatNumber(Number(prizeValue));
          prizeText = t('currencyunit'); // Add a default text like a currency unit
        }
  
        const prizeKey = data.claimed_prizes && data.claimed_prizes.length > 0 ? t('alreadyclaimed') : t('successful');
  
        setPrize(prizeAmount || prizeText); // Show either amount or text
        setMessage(t(prizeKey));

        ClaimChecker();
      } else {
        setError(t(`error_messages.${data.message}`)); // Translate error message if available
      }
    } catch (err) {
      setError(t('errorclaimingprize')); // Translate the error message
    } finally {
      setLoading(false);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay noblur" onClick={handleClose}>
      <div className="modal-content surprise-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h4 className="modal-title"></h4>
          <button className="close-button" onClick={handleClose}>
            <FiX />
          </button>
        </div>
        <div className="modal-body surprise-box">
          <h5>{t('youwon')}</h5>
          {loading ? (
            <p>{t('loading')}</p>
          ) : prize ? (
            <>
              <span className='prize-money'>{prize}</span>
              <p className='prize-message'>{message}</p>
            </>
          ) : (
            error && <p className="error-text">{error}</p>
          )}
          <p>{t('forfurtherdetails')}</p>
        </div>
        <div className="modal-footer">
          <div className='btn-dsgn'>
            {prize ? (
              <button onClick={handleClose}>
                {t('alreadyclaimedbonus')}
              </button>
            ) : (
              <button onClick={handleClaimPrize}>
                {t('claimbonus')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenBox;
